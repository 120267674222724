<template>
    <div>
        <div class="row mt-5">
            <div class="col-md-10 offset-md-1">

                <div class="card card-custom gutter-b">
                    <div class="card-header">
                        <div class="card-title">
                            <h3 class="card-label">1. Shipment Information</h3>
                        </div>
                    </div>
                    <div class="card-body body-fluid">
                        <div class="row">
                            <div class="col-md-3">
                                <label class="font-weight-bold">Shipping Mode <span class="text-danger">*</span></label>
                                <b-form-select :options="shippingmodelist" value-field="name" v-model="form.shipping_mode"
                                    text-field="name">
                                </b-form-select>
                            </div>
                            <div class="col-md-3">
                                <label class="font-weight-bold">Shipment Type <span class="text-danger">*</span></label>
                                <b-form-select :options="shipmenttypelist" value-field="name" v-model="form.shipment_type"
                                    text-field="name">
                                </b-form-select>
                            </div>
                            <div class="col-md-3">
                                <label class="font-weight-bold">Consolidation Required?</label>
                                <b-form-select :options="YesNolist" value-field="name" v-model="form.consolidation_required"
                                    @change="checkConsolidation" text-field="name">
                                    <template #first>
                                        <b-form-select-option :value="null">Choose</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </div>
                            <div class="col-md-3">
                                <label class="font-weight-bold">Total Orders</label>
                                <b-form-input type="number" v-model="form.total_orders"></b-form-input>
                            </div>
                            <div class="col-12 mt-2">
                                <label class="font-weight-bold">Shipping Address <span class="text-danger">*</span></label>
                                <b-form-select value-field="id" v-model="form.shipping_address" text-field="name">
                                    <b-form-select-option v-for="item in virtualaddresses" :key="item.id"
                                        :value="item.id">{{ item.name }}, {{ item.address_line_1 }},
                                        {{ item.city_state }}, {{ item.country }}-{{
                                            item.zip
                                        }}</b-form-select-option>
                                </b-form-select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card card-custom gutter-b">
                    <div class="card-header">
                        <div class="card-title">
                            <h3 class="card-label">2. Purchase Information</h3>
                        </div>
                        <div class="card-toolbar" v-if="form.consolidation_required == 'Yes'">
                            <b-button variant="secondary" @click="addVendorToList"><i class="flaticon2-plus"></i> Add
                                Vendor</b-button>
                        </div>
                    </div>
                    <div class="card-body body-fluid">
                        <div class="row" v-if="form.consolidation_required == null || form.consolidation_required == 'No'">
                            <div class="col-md-4">
                                <label class="font-weight-bold">Vendor Name <span class="text-danger">*</span></label>
                                <b-form-input placeholder="Amazon.com, ebay etc..."
                                    v-model="form.vendor_name"></b-form-input>
                            </div>
                            <div class="col-md-4">
                                <label class="font-weight-bold">Courier Name <span class="text-danger">*</span></label>
                                <b-form-select :options="couriers" value-field="name" v-model="form.courier"
                                    text-field="name" size="md" class="">
                                    <template #first>
                                        <b-form-select-option :value="null" disabled>Select Courier</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </div>
                            <div class="col-md-4">
                                <label class="font-weight-bold">Courier Tracking Number
                                    <span class="text-danger">*</span></label>
                                <b-form-input placeholder="Tracking number"
                                    v-model="form.courier_tracking_no"></b-form-input>
                            </div>
                            <div class="col-md-6 mt-2">
                                <label class="font-weight-bold">Purchase Date <span class="text-danger">*</span></label>
                                <b-form-input type="date" v-model="form.purchase_date"></b-form-input>
                            </div>
                            <div class="col-md-6 mt-2">
                                <label class="font-weight-bold">Purchase Invoice (JPG,PNG and PDF)</label>
                                <b-form-file accept=".jpg, .png, .gif, .pdf" plain class="form-control"
                                    @change="onFileChange"></b-form-file>
                            </div>
                        </div>
                        <div class="row" v-if="form.consolidation_required == 'Yes'">
                            <div class="col-md-4">
                                <label class="font-weight-bold">Vendor Name <span class="text-danger">*</span></label>
                                <b-form-input placeholder="Amazon.com, ebay etc..."
                                    v-model="vendor.vendor_name"></b-form-input>
                            </div>
                            <div class="col-md-4">
                                <label class="font-weight-bold">Courier Name <span class="text-danger">*</span></label>
                                <b-form-select :options="couriers" value-field="name" v-model="vendor.courier"
                                    text-field="name" size="md" class="">
                                    <template #first>
                                        <b-form-select-option :value="null" disabled>Select Courier</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </div>
                            <div class="col-md-4">
                                <label class="font-weight-bold">Courier Tracking Number
                                    <span class="text-danger">*</span></label>
                                <b-form-input placeholder="Tracking number"
                                    v-model="vendor.courier_tracking_no"></b-form-input>
                            </div>
                            <div class="col-md-6 mt-2">
                                <label class="font-weight-bold">Purchase Date <span class="text-danger">*</span></label>
                                <b-form-input type="date" v-model="vendor.purchase_date"></b-form-input>
                            </div>
                            <div class="col-md-6 mt-2">
                                <label class="font-weight-bold">Purchase Invoice (JPG,PNG and PDF)</label>
                                <b-form-file accept=".jpg, .png, .gif, .pdf" plain class="form-control"
                                    @change="onVendorFileChange"></b-form-file>
                            </div>
                            <div class="col-12" v-if="form.consolidation_required == 'Yes' && vendorList.length > 0">
                                <div class="table-responsive">
                                    <table class="table table-bordered text-nowrap" id="productTable">
                                        <thead>
                                            <tr>
                                                <th>Vendor</th>
                                                <th>Courier</th>
                                                <th>Tracking No.</th>
                                                <th>Purchase Date</th>
                                                <th>Remove</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="item" v-for="(v, index) in vendorList" :key="index">
                                                <td>
                                                    {{ v.vendor_name }}
                                                </td>
                                                <td>
                                                    {{ v.courier }}
                                                </td>
                                                <td>
                                                    {{ v.courier_tracking_no }}
                                                </td>
                                                <td>
                                                    {{ v.purchase_date }}
                                                </td>
                                                <td>
                                                    <b-button @click="removeVendor(index)" class="ml-2" size="sm"
                                                        variant="danger">
                                                        <i class="flaticon2-trash icon-sm"></i></b-button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card card-custom gutter-b">
                    <div class="card-header">
                        <div class="card-title">
                            <h3 class="card-label">3. Product Information</h3>
                        </div>
                        <div class="card-toolbar">
                            <b-button variant="secondary" @click="addProduct"><i class="flaticon2-plus"></i> Add
                                item</b-button>
                        </div>
                    </div>
                    <div class="card-body body-fluid">
                        <div class="table-responsive">
                            <table class="table text-nowrap" id="productTable">
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Weight Scale</th>
                                        <th>Size Scale</th>
                                        <th>Length</th>
                                        <th>Width</th>
                                        <th>Height</th>
                                        <th>Dimensional weight</th>
                                        <th>Actual weight</th>
                                        <th>Final weight</th>
                                        <th>Qty</th>
                                        <th>Currency</th>
                                        <th>Value</th>
                                        <th>Total value</th>
                                        <th>Remove</th>
                                    </tr>
                                </thead>
                                <tbody id="p_scents">
                                    <tr class="item" v-for="(itemproduct, index) in form.products" :key="index">
                                        <td>
                                            <input style="min-width: 200px" type="text" class="form-control productName"
                                                list="producttypes" placeholder="Product description" id="pname"
                                                v-model="itemproduct.description" />
                                        </td>
                                        <td>
                                            <b-form-select :options="weightscales" value-field="name"
                                                v-model="itemproduct.weight_scale" text-field="name" class="form-control"
                                                @change="CalculateDimensionalWeight(index)">
                                            </b-form-select>
                                        </td>
                                        <td>
                                            <b-form-select :options="sizescales" value-field="name"
                                                v-model="itemproduct.size_scale" text-field="name" class="form-control">
                                            </b-form-select>
                                        </td>
                                        <td>
                                            <input type="number" step=".01" placeholder="0.00" class="form-control length"
                                                name="plength[]" v-model="itemproduct.length" id="plength"
                                                @keyup="CalculateDimensionalWeight(index)"
                                                @change="CalculateDimensionalWeight(index)" />
                                        </td>
                                        <td>
                                            <input type="number" step=".01" placeholder="0.00" class="form-control width"
                                                name="pwidth[]" v-model="itemproduct.width" id="pwidth"
                                                @keyup="CalculateDimensionalWeight(index)"
                                                @change="CalculateDimensionalWeight(index)" />
                                        </td>
                                        <td>
                                            <input type="number" step=".01" placeholder="0.00" class="form-control height"
                                                v-model="itemproduct.height" @keyup="CalculateDimensionalWeight(index)"
                                                @change="CalculateDimensionalWeight(index)" />
                                        </td>
                                        <td>
                                            <input type="text" class="form-control vweight" name="pvweight[]" readonly=""
                                                v-model="itemproduct.dimensional_weight" placeholder="0.00" />
                                        </td>
                                        <td>
                                            <input type="text" class="form-control aweight" name="paweight[]"
                                                placeholder="0.00" v-model="itemproduct.actual_weight"
                                                @change="CalculateFinalWeight(index)"
                                                @keyup="CalculateFinalWeight(index)" />
                                        </td>
                                        <td>
                                            <input type="number" step=".01" class="form-control weight" readonly=""
                                                placeholder="0.00" name="pweight[]" v-model="itemproduct.final_weight" />
                                        </td>
                                        <td>
                                            <input type="number" placeholder="0" id="pquantity" name="pquantity[]"
                                                class="form-control quantity" v-model="itemproduct.qty"
                                                @change="CalculateTotalValue(index)" @keyup="CalculateTotalValue(index)" />
                                        </td>
                                        <td>
                                            <b-form-select :options="currencies" value-field="code"
                                                v-model="itemproduct.currency" text-field="code" class="form-control">
                                            </b-form-select>
                                        </td>
                                        <td>
                                            <input type="number" step=".01" placeholder="0.00" class="form-control price"
                                                value="0.00" name="pprice[]" v-model="itemproduct.value" id="pprice"
                                                @change="CalculateTotalValue(index)" @keyup="CalculateTotalValue(index)" />
                                        </td>
                                        <td>
                                            <input type="number" step=".01" placeholder="0.00" class="form-control total"
                                                value="0.00" readonly="" v-model="itemproduct.total" name="ptotal[]"
                                                id="ptotal" />
                                        </td>
                                        <td>
                                            <b-button @click="removeProduct(index)" class="ml-2" size="sm" variant="danger">
                                                <i class="flaticon2-trash icon-sm"></i></b-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="card card-custom gutter-b">
                    <div class="card-header">
                        <div class="card-title">
                            <h3 class="card-label">4. Receiver Information</h3>
                        </div>
                        <div class="card-toolbar">
                            <b-button v-b-modal.address-book-modal class="mr-2" size="sm" variant="light"><i
                                    class="flaticon2-location"></i> Address book</b-button>
                            <b-button variant="light" size="sm" @click="saveAddress()"><i class="flaticon2-add-1"></i> Save
                                Address
                            </b-button>
                        </div>
                    </div>
                    <div class="card-body body-fluid">
                        <div class="row">
                            <div class="col-md-4">
                                <label class="font-weight-bold">Name <span class="text-danger">*</span></label>
                                <b-form-input placeholder="Receiver name" v-model="form.receiver_name"></b-form-input>
                            </div>
                            <div class="col-md-4">
                                <label class="font-weight-bold">Phone <span class="text-danger">*</span></label>
                                <b-form-input placeholder="Phone number" v-model="form.receiver_phone"></b-form-input>
                            </div>
                            <div class="col-md-4">
                                <label class="font-weight-bold">Email </label>
                                <b-form-input type="email" placeholder="Email address"
                                    v-model="form.receiver_email"></b-form-input>
                            </div>
                            <div class="col-md-4 mt-2">
                                <label class="font-weight-bold">Country <span class="text-danger">*</span></label>
                                <b-form-select :options="countries" value-field="name" text-field="name"
                                    v-model="form.receiver_country" @change="changeCountry" size="md"
                                    class=""></b-form-select>
                            </div>
                            <div class="col-md-4 mt-2">
                                <label class="font-weight-bold">State <span class="text-danger">*</span></label>
                                <b-form-select :options="states" value-field="name" text-field="name"
                                    v-model="form.receiver_state" size="md" class="">
                                    <template #first>
                                        <b-form-select-option :value="null" disabled>Select State</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </div>
                            <div class="col-md-4 mt-2">
                                <label class="font-weight-bold">Zip code <span class="text-danger">*</span></label>
                                <b-form-input id="postal" type="text" v-model="form.receiver_zipcode"
                                    placeholder="Postal Code" @change="changePostalCode"
                                    @keyup="changePostalCode"></b-form-input>
                            </div>
                            <div class="col-md-8 mt-2">
                                <label class="font-weight-bold">Street Address <span class="text-danger">*</span></label>
                                <b-form-input placeholder="Street address"
                                    v-model="form.receiver_street_address"></b-form-input>
                            </div>
                            <div class="col-md-4 mt-2">
                                <label class="font-weight-bold">City <span class="text-danger">*</span></label>
                                <b-form-input placeholder="City name" v-model="form.receiver_city"></b-form-input>
                            </div>

                            <div class="col-md-12 mt-3">
                                <label class="font-weight-bold">Shipping Estimation</label>
                                <p>
                                    Shipping estimation not available for the selected values. You
                                    can change the booking details or continue booking our team
                                    will inform you once shipping charges are updated to your
                                    booking.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <b-button variant="primary"
                            v-if="form.consolidation_required == 'No' || form.consolidation_required == null"
                            @click="addprealert">Submit Request</b-button>
                        <b-button variant="primary" v-if="form.consolidation_required == 'Yes'"
                            @click="addConsolidatePrealert">Submit Request</b-button>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="address-book-modal" ref="modal" ok-title="Close" title="Address Book" size="lg" ok-only>
            <div class="row" v-for="(item, index) in addressbook" :key="index">
                <div class="col-md-9">
                    <span class="float-left">
                        <h5 class="text-purple">{{ item.name }}</h5>

                        {{ item.phone }}
                        <br />
                        {{
                            item.street_address +
                            ", " +
                            item.city +
                            "/" +
                            item.country +
                            ", " +
                            item.state +
                            " / " +
                            item.zip
                        }}
                    </span>
                </div>
                <div class="col-md-3">
                    <b-button variant="primary" @click="chooseaddress(item.id)" class="float-right">Choose
                        address</b-button>
                </div>
            </div>
        </b-modal>
        <datalist id="producttypes">
            <option v-for="(item, index) in proddesclist" :key="index">
                {{ item.name }}
            </option>
        </datalist>
        <loading :active.sync="isLoading"></loading>
    </div>
</template>

<style>
.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>th {
    padding: 10px;
}

#productTable .form-control {
    min-width: 100px;
}

.vweight {
    width: 141px;
}

.modal-open {
    overflow: initial;
}
</style>

<script>
import {
    SET_BREADCRUMB
} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import singaporePostalCode from "@/assets/data/singaporePostalCode.json";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
    mapGetters
} from "vuex";
export default {
    data() {
        return {
            file: null,
            errors: [],
            addressbook: [],
            isLoading: false,
            firstweightscale: null,
            firstsizescale: null,
            firstcurrency: null,
            adressObj: {},
            vendor: {
                vendor_name: null,
                courier_tracking_no: null,
                purchase_invoice_url: null,
                purchase_date: null,
                courier: null,
                file: null,
            },
            vendorList: [],
            product: {
                description: null,
                weight_scale: "Kgs",
                size_scale: "CM",
                length: null,
                width: null,
                height: null,
                dimensional_weight: null,
                actual_weight: null,
                final_weight: null,
                qty: null,
                currency: "USD",
                value: null,
                total: null,
            },
            form: {
                vendor_name: null,
                courier_tracking_no: null,
                purchase_invoice_url: null,
                purchase_date: null,
                courier: null,
                shipping_address: null,
                shipping_mode: null,
                shipment_type: null,
                consolidation_required: null,
                total_orders: null,
                receiver_id: null,
                receiver_name: null,
                receiver_phone: null,
                receiver_email: null,
                receiver_street_address: null,
                receiver_city: null,
                receiver_country: null,
                receiver_country_id: null,
                receiver_state: null,
                receiver_zipcode: null,
                products: [],
                personal_effects: false,
                vendors: []
            },
            couriers: [],
            proddesclist: [],
            weightscales: [],
            sizescales: [],
            currencies: [],
            shippingmodelist: [],
            shipmenttypelist: [],
            virtualaddresses: [],
            allstates: [],
            states: [],
            countries: [],
            YesNolist: [{
                name: "Yes",
            },
            {
                name: "No",
            },
            ],
            singaportpostalcodes: singaporePostalCode,
        };
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{
            title: "Prealert",
        },
        {
            title: "Add",
        },
        ]);
        this.isLoading = true;
        this.loadData();
        this.getAllStates();

        this.form.products.push({
            description: null,
            weight_scale: "Kgs",
            size_scale: "CM",
            length: null,
            width: null,
            height: null,
            dimensional_weight: null,
            actual_weight: null,
            final_weight: null,
            qty: null,
            currency: "USD",
            value: null,
            total: null,
        });
    },
    computed: {
        ...mapGetters(["currentUser"]),
    },
    components: {
        Loading,
    },
    methods: {
        CalculateTotalWeight() {
            this.form.total_weight = 0;
            this.form.products.forEach((value) => {
                if (value.final_weight > 0) {
                    this.form.total_weight =
                        parseFloat(this.form.total_weight) + parseFloat(value.final_weight);
                }
            });
            this.form.total_weight = Math.round(this.form.total_weight);
        },
        CalculateDimensionalWeight(index) {
            if (
                this.form.products[index].width > 0 &&
                this.form.products[index].height > 0 &&
                this.form.products[index].length > 0
            ) {
                // Calculate Dimensional Weight when weight scale is KG

                if (
                    this.form.products[index].weight_scale.toLowerCase() ==
                    "Kgs".toLowerCase()
                ) {
                    this.form.products[index].dimensional_weight =
                        (this.form.products[index].width *
                            this.form.products[index].height *
                            this.form.products[index].length) /
                        5000;
                }
                // Calculate Dimensional Weight when weight scale is M3
                if (
                    this.form.products[index].weight_scale.toLowerCase() ==
                    "M3".toLowerCase()
                ) {
                    this.form.products[index].dimensional_weight =
                        (this.form.products[index].width / 100) *
                        (this.form.products[index].height / 100) *
                        (this.form.products[index].length / 100);
                }
                this.CalculateFinalWeight(index);
            } else {
                this.form.products[index].dimensional_weight = 0;
            }
            this.CalculateTotalWeight();
        },
        CalculateFinalWeight(index) {
            if (
                this.form.products[index].dimensional_weight >=
                this.form.products[index].actual_weight
            ) {
                this.form.products[index].final_weight =
                    this.form.products[index].dimensional_weight;
            } else {
                this.form.products[index].final_weight =
                    this.form.products[index].actual_weight;
            }
            this.CalculateTotalWeight();
        },
        CalculateTotalValue(index) {
            if (
                this.form.products[index].qty > 0 &&
                this.form.products[index].value > 0
            ) {
                this.form.products[index].total =
                    this.form.products[index].qty * this.form.products[index].value;
            } else {
                this.form.products[index].total = 0;
            }
        },
        chooseaddress(id) {
            if (this.addressbook.filter((x) => x.id == id).length > 0) {
                var address = this.addressbook.filter((x) => x.id == id)[0];
                this.form.receiver_name = address.name;
                this.form.receiver_phone = address.phone;
                this.form.receiver_street_address = address.street_address;
                this.form.receiver_city = address.city;
                this.form.receiver_country = address.country;
                //this.changeCountry();
                this.form.receiver_state = address.state;
                this.form.receiver_zipcode = address.zip;
                this.form.receiver_email = address.email;
                this.form.receiver_id = address.id;
                this.form.receiver_country_id = address.country_id;
                this.states = [{ id: 1, name: address.state }];
                this.$bvModal.hide("address-book-modal");
            }
        },

        addProduct() {
            // this.form.products.push(this.product);
            this.form.products.push({
                description: null,
                weight_scale: this.firstweightscale,
                size_scale: this.firstsizescale,
                length: null,
                width: null,
                height: null,
                dimensional_weight: null,
                actual_weight: null,
                final_weight: null,
                qty: null,
                currency: this.firstcurrency,
                value: null,
                total: null,
            });
        },
        removeProduct(index) {
            if (confirm("Are you sure you want to delete the product details")) {
                this.form.products.splice(index, 1);
            }
        },
        removeVendor(index) {
            if (confirm("Are you sure you want to delete the vendor details")) {
                this.vendorList.splice(index, 1);
            }

        },
        onFileChange(e) {
            this.file = e.target.files || e.dataTransfer.files;
            this.file = this.file[0];
        },
        saveAddress() {
            this.adressObj.name = this.form.receiver_name;
            this.adressObj.phone = this.form.receiver_phone;
            this.adressObj.street_address = this.form.receiver_street_address;
            this.adressObj.city = this.form.receiver_city;
            this.adressObj.country = this.form.receiver_country;
            this.adressObj.country_id = this.form.receiver_country_id;
            this.adressObj.state = this.form.receiver_state;
            this.adressObj.zip = this.form.receiver_zipcode;
            this.adressObj.created_by = this.currentUser.id;
            this.adressObj.email = this.form.receiver_email;
            this.adressObj.id = this.form.receiver_id;
            this.isLoading = true;
            ApiService.post("addressbook", this.adressObj)
                .then((response) => {
                    this.$nextTick(() => {
                        this.makeToastVariant(
                            "success",
                            "Address saved successfully",
                            "Success"
                        );
                        this.isLoading = false;
                        this.getAddressBook();

                    });
                })
                .catch(({
                    response
                }) => {
                    this.isLoading = false;
                    for (let field of Object.keys(response.data.error)) {
                        this.makeToastVariant(
                            "danger",
                            response.data.error[field][0],
                            "Error"
                        );
                    }
                });
        },
        checkErrors() {
            if (
                this.form.vendor_name &&
                this.form.courier &&
                this.form.courier_tracking_no &&
                this.form.purchase_date &&
                this.form.shipping_address &&
                this.form.shipping_mode &&
                this.form.shipment_type &&
                this.form.consolidation_required &&
                this.form.receiver_name &&
                this.form.receiver_phone &&
                this.form.receiver_street_address &&
                this.form.receiver_city &&
                this.form.receiver_country &&
                this.form.receiver_state &&
                this.form.receiver_zipcode
            ) {
                return true;
            }
            this.errors = [];

            if (!this.form.vendor_name) {
                this.errors.push("Vendor Name is required.");
            }
            if (!this.form.courier) {
                this.errors.push("Courier Name is required.");
            }
            if (!this.form.courier_tracking_no) {
                this.errors.push("Courier Tracking Number is required.");
            }
            if (!this.form.purchase_date) {
                this.errors.push("Purchase Date is required.");
            }
            if (!this.form.shipping_address) {
                this.errors.push("Shipping Address is required.");
            }
            if (!this.form.shipping_mode) {
                this.errors.push("Shipping Mode is required.");
            }
            if (!this.form.shipment_type) {
                this.errors.push("Shipment Type is required.");
            }
            if (!this.form.consolidation_required) {
                this.errors.push("Consolidation Required value is required.");
            }
            if (!this.form.receiver_name) {
                this.errors.push("Receiver Name is required.");
            }
            if (!this.form.receiver_phone) {
                this.errors.push("Phone is required.");
            }
            if (!this.form.receiver_street_address) {
                this.errors.push("Street Address is required.");
            }
            if (!this.form.receiver_city) {
                this.errors.push("City is required.");
            }
            if (!this.form.receiver_country) {
                this.errors.push("Country is required.");
            }
            if (!this.form.receiver_state) {
                this.errors.push("State is required.");
            }
            if (!this.form.receiver_zipcode) {
                this.errors.push("Zip code is required.");
            }
            for (let field of this.errors) {
                this.makeToastVariant("danger", field, "Error");
            }
        },
        addprealert() {

            if (this.checkErrors() == true) {
                this.isLoading = true;
                this.saveprealert();
            }
        },
        savefile(shipment_id) {
            this.isLoading = true;
            let formData = new FormData();
            formData.append("file", this.file);
            formData.append("shipment_id", shipment_id);
            ApiService.upload("adddocument", formData)
                .then((response) => {
                    if (response.data.success) {
                        this.isLoading = false;
                        this.$router.push({
                            name: "shipments",
                        });
                    }
                })
                .catch(({
                    response
                }) => {
                    this.isLoading = false;
                    for (let field of Object.keys(response.data.error)) {
                        this.makeToastVariant(
                            "danger",
                            response.data.error[field][0],
                            "Error"
                        );
                    }
                });
        },
        saveprealert() {
            if (this.checkErrors() == true) {
                ApiService.post("prealert", this.form)
                    .then((res) => {
                        this.$nextTick(() => {
                            this.makeToastVariant(
                                "success",
                                "Prealert added successfully",
                                "Success"
                            );
                        });
                        if (this.file && res.data.id > 0) {
                            this.savefile(res.data.id);
                        } else {
                            this.$router.push({
                                name: "shipments",
                            });
                        }
                    })
                    .catch(({
                        response
                    }) => {
                        this.isLoading = false;
                        for (let field of Object.keys(response.data.error)) {
                            this.makeToastVariant(
                                "danger",
                                response.data.error[field][0],
                                "Error"
                            );
                        }
                        return -1;
                    });
            }
        },
        makeToastVariant(variant = null, text, title) {
            this.$bvToast.toast(text, {
                title: title,
                variant: variant,
                solid: true,
            });
        },
        changePostalCode() {
            if (
                this.form.receiver_country.toLowerCase() == "Singapore".toLowerCase() &&
                this.singaportpostalcodes.filter(
                    (f) => f.POSTAL == this.form.receiver_zipcode
                ).length > 0
            ) {
                this.form.receiver_street_address = this.singaportpostalcodes.filter(
                    (f) => f.POSTAL == this.form.receiver_zipcode
                )[0].ADDRESS;
                this.form.receiver_city = "Singapore";
            }
        },

        getAllStates() {
            ApiService.get("getstates")
                .then(({
                    data
                }) => {
                    this.allstates = data;
                    this.states = data;
                    if (
                        this.countries.filter((x) => x.name == this.form.receiver_country)
                            .length > 0
                    ) {
                        if (
                            this.allstates.filter(
                                (x) =>
                                    x.country_id ==
                                    this.countries.filter(
                                        (x) => x.name == this.form.receiver_country
                                    )[0].id
                            ).length > 0
                        ) {
                            this.states = this.allstates.filter(
                                (x) =>
                                    x.country_id ==
                                    this.countries.filter(
                                        (x) => x.name == this.form.receiver_country
                                    )[0].id
                            );
                        }
                    }
                })
                .catch(() => {
                    // context.commit(SET_ERROR, response.data.errors);
                    // context.commit(SET_ERROR, response);
                });
        },
        changeCountry() {

            // if (this.countries.filter((x) => x.name == this.form.receiver_country).length > 0) {
            //     if (
            //         this.allstates.filter(
            //             (x) =>
            //                 x.country_id ==
            //                 this.countries.filter(
            //                     (x) => x.name == this.form.receiver_country
            //                 )[0].id
            //         ).length > 0
            //     ) {
            //         this.form.receiver_country_id = this.countries.filter(
            //             (x) => x.name == this.form.receiver_country
            //         )[0].id;
            //         this.states = this.allstates.filter(
            //             (x) =>
            //                 x.country_id ==
            //                 this.countries.filter(
            //                     (x) => x.name == this.form.receiver_country
            //                 )[0].id
            //         );

            //         // if(this.form.receiver_state !=)
            //         //this.form.receiver_state = null;
            //     }
            // }

            // console.log(this.states[0].name);
            // //If Country has only one State auto select that state PW-68
            // if (this.states.length == 1) {
            //     this.form.receiver_state = this.states[0].name;
            // }
            var countryId = this.countries.filter((x) => x.name == this.form.receiver_country)[0].id;
            this.changeState(countryId);
        },
        changeState(countryId) {
            this.form.receiver_country_id = countryId;
            ApiService.get("getStatesByCountryId", countryId)
                .then(({ data }) => {

                    // this.allstates = data;
                    this.states = data;
                    this.isLoading = false;
                })
                .catch(() => {
                    // context.commit(SET_ERROR, response.data.errors);
                    // context.commit(SET_ERROR, response);
                });
        },
        getAddressBook() {
            ApiService.get("addressbook")
                .then(({
                    data
                }) => {
                    this.addressbook = data;
                })
                .catch(() => {
                });
        },
        loadData() {
            ApiService.get("getpageload")
                .then(({
                    data
                }) => {
                    this.addressbook = data.addressbook;
                    this.countries = data.countries;
                    this.form.receiver_country = this.countries.length ?
                        this.countries[0].name :
                        null;

                    this.virtualaddresses = data.va;
                    this.form.shipping_address = this.virtualaddresses.length ?
                        this.virtualaddresses[0].id :
                        null;

                    this.proddesclist = data.productDescriptions;
                    this.couriers = data.courier;
                    this.shippingmodelist = data.shippingModes;
                    this.form.shipping_mode = this.shippingmodelist.length ?
                        this.shippingmodelist[0].name :
                        null;
                    this.shipmenttypelist = data.shipmentTypes;
                    this.form.shipment_type = this.shipmenttypelist.length ?
                        this.shipmenttypelist[0].name :
                        null;
                    this.currencies = data.currencies;
                    this.firstcurrency = this.currencies.length ?
                        this.currencies[0].code :
                        null;
                    this.sizescales = data.sizescales;
                    this.firstsizescale = this.sizescales.length ?
                        this.sizescales[0].name :
                        null;
                    this.weightscales = data.weightScales;
                    this.firstweightscale = this.weightscales.length ?
                        this.weightscales[0].name :
                        null;

                    this.isLoading = false;
                })
                .catch(() => {
                    // context.commit(SET_ERROR, response.data.errors);
                    // context.commit(SET_ERROR, response);
                });
        },
        checkConsolidation() {
            if (this.form.consolidation_required == 'Yes') {
                //REQUIRED
            }
        },
        onVendorFileChange(e) {
            this.vendor.file = e.target.files || e.dataTransfer.files;
            this.vendor.file = this.vendor.file[0];
        },
        addVendorToList() {

            if (this.checkVendorForm()) {
                this.vendorList.push(this.vendor);
                this.vendor = {
                    vendor_name: null,
                    courier_tracking_no: null,
                    purchase_invoice_url: null,
                    purchase_date: null,
                    courier: null,
                    file: null
                };
            }

        },
        checkVendorForm() {
            if (
                this.vendor.vendor_name &&
                this.vendor.courier &&
                this.vendor.courier_tracking_no &&
                this.vendor.purchase_date
            ) {
                return true;
            }
            this.errors = [];

            if (!this.vendor.vendor_name) {
                this.errors.push("Vendor Name is required.");
            }
            if (!this.vendor.courier) {
                this.errors.push("Courier Name is required.");
            }
            if (!this.vendor.courier_tracking_no) {
                this.errors.push("Courier Tracking Number is required.");
            }
            if (!this.vendor.purchase_date) {
                this.errors.push("Purchase Date is required.");
            }

            for (let field of this.errors) {
                this.makeToastVariant("danger", field, "Error");
            }
        },
        checkConsolidateForm() {
            if (
                this.vendorList.length > 0 &&
                this.form.shipping_address &&
                this.form.shipping_mode &&
                this.form.shipment_type &&
                this.form.consolidation_required &&
                this.form.receiver_name &&
                this.form.receiver_phone &&
                this.form.receiver_street_address &&
                this.form.receiver_city &&
                this.form.receiver_country &&
                this.form.receiver_state &&
                this.form.receiver_zipcode
            ) {
                return true;
            }
            this.errors = [];


            if (this.vendorList.length < 1) {
                this.errors.push("Vendor details are required.");
            }

            if (!this.form.shipping_address) {
                this.errors.push("Shipping Address is required.");
            }
            if (!this.form.shipping_mode) {
                this.errors.push("Shipping Mode is required.");
            }
            if (!this.form.shipment_type) {
                this.errors.push("Shipment Type is required.");
            }
            if (!this.form.consolidation_required) {
                this.errors.push("Consolidation Required value is required.");
            }
            if (!this.form.receiver_name) {
                this.errors.push("Receiver Name is required.");
            }
            if (!this.form.receiver_phone) {
                this.errors.push("Phone is required.");
            }
            if (!this.form.receiver_street_address) {
                this.errors.push("Street Address is required.");
            }
            if (!this.form.receiver_city) {
                this.errors.push("City is required.");
            }
            if (!this.form.receiver_country) {
                this.errors.push("Country is required.");
            }
            if (!this.form.receiver_state) {
                this.errors.push("State is required.");
            }
            if (!this.form.receiver_zipcode) {
                this.errors.push("Zip code is required.");
            }
            for (let field of this.errors) {
                this.makeToastVariant("danger", field, "Error");
            }
        },
        addConsolidatePrealert() {
            if (this.checkConsolidateForm() == true) {
                this.form.vendors = this.vendorList;

                ApiService.post("addConsolidatePreAlert", this.form)
                    .then((res) => {
                        //console.log(res);
                        this.$nextTick(() => {
                            this.makeToastVariant(
                                "success",
                                "Prealert added successfully",
                                "Success"
                            );
                        });
                        if (res.data.id > 0) {
                            this.addConsolidatePrealertFiles(res.data);
                        }
                    })
                    .catch(({
                        response
                    }) => {
                        this.isLoading = false;
                        for (let field of Object.keys(response.data.error)) {
                            this.makeToastVariant(
                                "danger",
                                response.data.error[field][0],
                                "Error"
                            );
                        }
                        return -1;
                    });
            }
        },
        addConsolidatePrealertFiles(data) {
debugger;
            data.vendors.forEach((value, index) => {
                var vendor = this.vendorList.filter((x) => x.vendor_name == value.vendor_name)[0];

                let formData = new FormData();
                formData.append("file", vendor.file);
                formData.append("vendor_id", value.id);
                ApiService.upload("addVendorDocument", formData)
                    .then((response) => {
                        if (response.data.success) {

                        }
                    })
                    .catch(({
                        response
                    }) => {
                        this.isLoading = false;
                        for (let field of Object.keys(response.data.error)) {
                            this.makeToastVariant(
                                "danger",
                                response.data.error[field][0],
                                "Error"
                            );
                        }
                    });
                // this.isLoading = false;
                //         this.$router.push({
                //             name: "shipments",
                //         });

            });
            this.$router.push({
                name: "shipments",
            });

            this.isLoading = true;

        }
    },
};
</script>
